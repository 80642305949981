@import 'vars/colors';
@import 'vars/typography';
@import 'vars/environment';

html,
body {
	height: 100%;
}

body {
	margin: 0;
	background-color: $pageBackground;
	background: linear-gradient(to bottom right, #2a5cad, #236397);
	background-attachment: fixed;

	min-width: 1000px;

	&.no-scroll {
		overflow: hidden;
	}
}

a {
	color: inherit;
	text-decoration: none;
}

hr {
	border: none;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	margin: $space 0;
}

textarea {
	overflow-x: hidden;
}

*:focus {
	outline: 0;
}

.lit-link {
	@include link;
}

.lit-wrapper {
	@include wrapper(960);

	&--wide {
		@include wrapper(1140);
	}

	&--article {
		@include wrapper(680);
	}

	&--thin {
		@include wrapper($widthThin);
	}

	&--tiny {
		@include wrapper($inputWidth);
	}
}

.relative {
	position: relative;
}
.cl {
	cursor: pointer;
}
.normal {
	font-weight: 400;
}
.block {
	display: block;
}
