@import 'breakpoints';

$white: #fff;
$offwhite: #F7F7F7;
$offwhiteWarm: #e7e9e8;
$lightGray: #EAEAEA;
$mediumGray: #d4d4d4;
$gray: #999;
$black: #555;
$blue: #46b6e5;
$teal: #3CBDB2;
$aqua: #4bbc9d;
$orange: #fd8122;
$pink: #d44368;
$peach: #f6918c;
$green: #56f0b4;
$purple: #3629b5;

$primary: $orange;
$secondary: $blue;
$tertiary: $purple;
$interactiveColor: $purple;
$positiveColor: #26ace4;
$negativeColor: $pink;
$textColor: $white;
$textColorBright: $white;
$textColorDark: $black;
$linkColor: $tertiary;

$pageBackground: $blue;

@function calculateTextColor($color) {
	@if (lightness($color) > 72) {
		@return $textColor; // Lighter backgorund, return dark color
	}
	@else {
		@return $white; // Darker background, return light color
	}
}
@mixin bgColor($color) {

	background-color: $color;
	color: calculateTextColor($color);

	&.bg-border {
		background-color: transparent;
		color: $color;
		border: 1px solid;
	}

	&.bg-interactive, &.lit-btn {

		cursor: pointer;

		@include transitions {
			transition: background-color 0.12s, box-shadow 0.2s;

			&:hover {
				background-color: darken($color, 8%);
			}

		}

		&:active {
			transition: none;
			background-color: darken($color, 14%);
		}

		&:disabled, &.disabled {
			cursor: default;
			opacity: 0.3;

			@include transitions {
				transition: none;

				&:hover {
					background-color: $color;
				}

			}
		}

		&.bg-border {
			&:active {
				background-color: transparent;
				color: darken($color, 8%);
			}

			@include transitions {

				transition: color 0.2s, background-color 0.2s, border-color 0.2s, box-shadow 0.2s;

				&:hover {
					color: calculateTextColor($color);
					border-color: $color;
					background-color: $color;
				}

			}
			&:active {
				transition: none;
				background-color: darken($color, 6%);
				border-color: darken($color, 6%);
				color: darken(calculateTextColor($color), 8%);
			}
		}
	}

}
