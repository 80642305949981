$space: 30px;
$space-xsm: 7px;
$space-sm: ($space / 2);
$space-lg: ($space * 2);
$space-xlg: ($space * 4);
$space-section: 150px;

$wrapperCollapseMargin: 20;
$wrapperCollapseMobileMargin: 10;

$contentMargin: 1.5em;
$contentMarginLarge: 3em;
