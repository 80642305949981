@import 'vars/typography';
@import 'vars/breakpoints';

.lit-sans-serif {
	font-family: $sansSerif;
}
.lit-serif {
	font-family: $serif;
}

body {
	font-family: $fontFamily;
	color: $textColor;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0 0 $space;

	+ p {
		margin-top: 0;
	}
}

h1,
.lit-h1 {
	@include h1;
}

h2,
.lit-h2 {
	@include h2;
}

h3,
.lit-h3 {
	@include h3;
}

h4,
.lit-h4 {
	@include h4;
}

p {
	line-height: 1.4;
	@include p;
}

.lit-page-title {
	text-align: center;
	margin: $space 0 0;

	&:after {
		content: '';
		display: block;
		margin: $space-sm auto;
		max-width: 80px;
		height: 2px;
		background-color: rgba($white, 0.2);
	}
}

.lit-landing-page-title {
	text-align: center;
	margin: $space 0 0;

	// &:after {
	// 	content: '';
	// 	display: block;
	// 	margin: $space-sm auto;
	// 	max-width: 80px;
	// 	height: 2px;
	// 	background-color: rgba($white, 0.2);
	// }
}

.lit-landing-page-subtitle {
	text-align: center;
	margin: 0;
	font-weight: 400;
	font-size: 16px;
	letter-spacing: 0.5px;

	&:after {
		content: '';
		display: block;
		margin: $space-sm auto;
		max-width: 80px;
		height: 2px;
		background-color: rgba($white, 0.2);
	}
}

.lit-page-description {
	text-align: center;
	margin: 0 auto $space-lg;
	font-size: $p;
	line-height: 1.3;
	max-width: 460px;
	text-align: center;
}
