@import 'vars';

.lit-preview {
	margin: $space 0;
	background-color: rgb(239, 245, 245);
	padding: 36px $space-sm $space-sm;
	display: inline-block;
	border-radius: 4px;
	font-size: 0;
	position: relative;
	box-shadow: $boxShadow2;

	&__label {
		color: rgba(2, 2, 2, 0.5);
		letter-spacing: $letterSpacing;
		display: block;
		position: absolute;
		top: 10px;
		left: $space-xsm;
		right: $space-xsm;
		text-align: center;
		font-size: $small;
		text-transform: uppercase;
		margin: 0;
	}

	&__video {
		box-shadow: $boxShadow2;
	}
}
