.environment-banner{
    height: 35px;
    background-color:rgb(237, 172, 115);
    display: none;
    justify-content: center;
    align-items: center;

    &.development{
        background-color:rgb(237, 115, 115);
        display: flex;
    }

    &.testing{
        background-color:rgb(237, 172, 115);
        display: flex;
    }
}