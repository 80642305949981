@import 'mixins';

$serif: 'Playfair Display', serif;
$sansSerif: 'Open sans', sans-serif;
$fontFamily: $sansSerif;
$headingFontFamily: 'Quicksand', sans-serif;

@mixin heading {
	font-family: $headingFontFamily;
	font-weight: normal;
	letter-spacing: -0.04em;
}

$h1: 48px;
$h2: 35px;
$h3: 24px;
$h4: 18px;
$p: 18px;
$small: 14px;
$tiny: 12px;

$letterSpacing: 0.03em;

@mixin article {

	> :first-child {
		margin-top: 0;
	}

	> :last-child {
		margin-bottom: 0;
	}

	h1, h2, h3, h4, h5, h6 {
		margin: 2.5em 0 0;
		font-weight: 600;
		letter-spacing: $letterSpacing;
	}

	&--uc {
		h1, h2, h3, h4, h5, h6 {
			text-transform: uppercase;
		}
	}

	p {
		font-size: 0.9em;
	}

	a {
		@include link;
	}

}

@mixin sansSerif() {
	font-family: $sansSerif;
	font-style: normal;
	font-weight: normal;
}

@mixin serif() {
	font-family: $serif;
	font-style: italic;
}

@mixin h1 {
	font-size: $h1;
	line-height: 1.18;
    @include heading;

	@include mobile {
		font-size: $h2;
	}

	@include mobileThin {
		font-size: 22px;
	}
}

@mixin h2 {
	font-size: $h2;
	line-height: 1.2;
	font-weight: bold;
    @include heading;

	@include mobile {
		font-size: $h3;
	}

	@include mobileThin {
		font-size: 18px;
	}
}

@mixin h3 {
	font-size: $h3;
	font-weight: bold;
    @include heading;

	@include mobile {
		font-size: $h4;
	}
}

@mixin h4 {
	font-size: $h4;
	font-weight: bold;
    @include heading;
}

@mixin p {
	font-size: $p;
}
