@import 'spacing';

$wideContentWrapperWidth: 1440;
$outerContentWrapperWidth: 1140;
$articleContentWrapperWidth: 680;

// content widths
$widthThin: 400;
$widthBox: 560;

// border radius
$borderRadius: 40px;

// Header
$headerHeight: 88px;
$headerMobileHeight: 40px;

// layers
$zBg: 0;
$zTimelineDots: 50;
$zTimelineCards: 60;
$zBreakpointSelector: 2;
$zModal: 70;

// box shadows
$boxShadow3: 0px 6px 14px rgba(0,0,0,0.1);
$boxShadow2: 3px 3px 30px rgba(0,0,0,0.1);
$boxShadow1: 0 0 20px rgba(0,0,0,0.05);

// Timeline dots
$proximitySize: 72px;
$dotSize: $space * 1.5;

// single event
$singleEventWidth: 1200px;

// editor
$sidebarWidth: 76px;
$parametersWidth: 325px;

// form controls
$inputWidth: 300;
$inputHeight: 63px;
